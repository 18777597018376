import _ from 'lodash'

import spellIdListByClassAndLvl from '@/constants/spellIdListByClassAndLvl'
import { spellCollection } from '@/constants/spellList'
import { PC_CLASS_WIZARD } from '@/constants/pcClassIdList'

import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

import SPELL_LVL_MIN from '@/components/SpellBookGenerator/constants/SPELL_LVL_MIN'

const MIN_SPELL_BY_LVL = 1
const MAX_SPELL_BY_LVL = 10
const SPELL_LVL_COEF = .8

const pickSpellIdList = lvl => spellIdListByClassAndLvl[PC_CLASS_WIZARD][lvl].filter(
  spellId => (
    spellCollection[spellId]
    && !spellCollection[spellId].isReReleased
    && !spellCollection[spellId].isAbandoned
  )
)

const generateSpellIdListByLvl = lvl => {
  const result = []

  let spellIdListToPick = pickSpellIdList(lvl)
  let count = _.random(
    MIN_SPELL_BY_LVL,
    Math.max(
      MIN_SPELL_BY_LVL,
      MAX_SPELL_BY_LVL - Math.round(lvl * SPELL_LVL_COEF)
    )
  )

  while (count--) {
    const spellId = _.sample(spellIdListToPick)

    spellIdListToPick = spellIdListToPick.filter(id => id !== spellId)

    result.push(spellId)
  }

  return result
}

export default ({ lvl }) => {
  const result = []

  for (let i = SPELL_LVL_MIN; i <= lvl; i++) {
    result.push({
      lvl: i,
      list: generateSpellIdListByLvl(i),
    })
  }

  trackAnalyticEvent('generate-spellbook')

  return result
}

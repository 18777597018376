import React from 'react'

import ContentGenerator from '@/components/ContentGenerator'
import PageTitle from '@/components/PageTitle'
import { SOURCE_WDH } from '@/constants/sourceList'

import typeList from './constants/typeList'

import generateSpellBook from './utils/generateSpellBook'

import SpellBook from './components/SpellBook'
import SourceInfo from '@/components/SourceInfo'

export default () => (
  <section>
    <PageTitle>Генератор книг заклинаний для D&D</PageTitle>
    <p>Цены заклинаний 1–5 уровней взяты из приключения <SourceInfo source={SOURCE_WDH} useFullName/>. Цены для 6–9 уровней рассчитаны по ним.</p>
    <ContentGenerator
      generate={generateSpellBook}
      typeList={typeList}
      ResultRenderer={SpellBook}
    />
  </section>
)

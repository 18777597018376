import React from 'react'
import PropTypes from 'prop-types'

import { spellCollection } from '@/constants/spellList'

import sortByName from '@/utils/sortByName'

import costByLvl from './constants/costByLvl'

import SpellBookComponent from './SpellBookComponent'

const SpellBookContainer = ({ result }) => {
  if (result.length) {
    const bookCost = result.reduce(
      (cost, { lvl, list }) => cost + costByLvl[lvl] * list.length,
      0
    )

    const spellCount = result.reduce(
      (count, { list }) => count + list.length,
      0
    )

    const spellByLvlList = result.map(
      ({ lvl, list }) => ({
        lvl,
        list: list
          .map(id => spellCollection[id])
          .sort(sortByName),
      })
    )

    return (
      <SpellBookComponent
        spellByLvlList={spellByLvlList}
        spellCount={spellCount}
        bookCost={bookCost}
      />
    )
  }

  return null
}

SpellBookContainer.propTypes = {
  result: PropTypes.array,
}

SpellBookContainer.defaultProps = {
  result: [],
}

export default SpellBookContainer

import React from 'react'
import PropTypes from 'prop-types'
import declint from 'declint-ru'

import Price from '@/components/Price'

import SpellByLvlList from './components/SpellByLvlList'

import './SpellBookStyles.less'

const spellWord = ['заклинание', 'заклинания', 'заклинаний']

const SpellBookComponent = ({ spellCount, bookCost, ...rest }) => {
  const spellCountText = declint(spellCount, spellWord)
  const writenText = declint(spellCount, ['записано', 'записаны', 'записано'])

  return (
    <section className='SpellBook'>
      <p className='SpellBook_info'>В книге {writenText} {spellCount} {spellCountText}.</p>
      <p className='SpellBook_info'>Примерная стоимость такой книги заклинаний <Price price={bookCost * 100}/>. Но найти покупателя будет непросто.</p>
      <SpellByLvlList {...rest}/>
    </section>
  )
}

SpellBookComponent.propTypes = {
  spellCount: PropTypes.number,
  bookCost: PropTypes.number,
}

SpellBookComponent.defaultProps = {
  spellCount: 0,
  bookCost: 0,
}

export default SpellBookComponent

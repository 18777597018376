export default [
  25,
  25,
  75,
  150,
  300,
  750,
  900, // Как показал ChatGPT4, значения меняются примерно по формуле ЦЕНА = 25 × УРОВЕНЬ^2
  1225,
  1600,
  2025,
]

import SPELL_LVL_MAX from './SPELL_LVL_MAX'
import SPELL_LVL_MIN from './SPELL_LVL_MIN'

const spellLvlList = []

for (let i = SPELL_LVL_MIN; i <= SPELL_LVL_MAX; i++) {
  spellLvlList.push({
    text: i,
    value: i,
  })
}

export default spellLvlList

import React from 'react'
import PropTypes from 'prop-types'

import SpellList from '@/components/SpellList'

import './SpellByLvlListStyles.less'

const SpellByLvlListComponent = ({ spellByLvlList }) => (
  <ol className='SpellByLvlList'>
    {
      spellByLvlList.map(
        ({ lvl, list }) => (
          <li
            className='SpellByLvlList_lvlItem'
            key={lvl}
          >
            <header className='SpellByLvlList_lvlHeader'>{lvl} уровень</header>
            <SpellList list={list}/>
          </li>
        ),
      )
    }
  </ol>
)

SpellByLvlListComponent.propTypes = {
  spellByLvlList: PropTypes.array,
  spellCount: PropTypes.number,
}

SpellByLvlListComponent.defaultProps = {
  spellByLvlList: [],
  spellCount: 0,
}

export default SpellByLvlListComponent
